import { CustomerAvatar, CompanyLogo } from '@cycle-app/ui';

import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

import { useQuoteId } from './QuoteContext';

export const QuotePanelCustomer = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  const customer = doc?.customer;
  if (!customer) return null;
  const singleCustomer = customer?.company && customer.company.countCustomers === 1;
  return (
    <DocCompanyCustomer
      doc={doc}
      layer={Layer.DropdownModalZ3}
      isDisabled
      className="!p-0"
    >
      <div className="flex items-center gap-2 px-2 font-medium text-primary">
        {singleCustomer
          ? (
            <>
              <CompanyLogo
                company={customer.company}
                size="XS"
              />
              {customer.company?.name}
            </>
          )
          : (
            <>
              <CustomerAvatar
                customer={customer}
                hideCompany
                size="XS"
              />
              {customer.displayName}
            </>
          )}
      </div>
    </DocCompanyCustomer>
  );
};
