import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import { ReactNode, MouseEventHandler } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { Layer } from 'src/types/layers.types';

import { ColorIcon } from './TextColorDropdown.styles';

type TextColorDropdownProps = {
  onSelect: (color: string) => void;
  button: (onClick: MouseEventHandler<Element>) => ReactNode;
  onClear: VoidFunction;
};

export const editorColorOptions: SelectOption[] = [
  {
    label: 'Blue',
    value: ColorTheme.Blue500,
    icon: <ColorIcon $color={ColorTheme.Blue500} />,
  },
  {
    label: 'Green',
    value: ColorTheme.Green700,
    icon: <ColorIcon $color={ColorTheme.Green700} />,
  },
  {
    label: 'Yellow',
    value: ColorTheme.Yellow700,
    icon: <ColorIcon $color={ColorTheme.Yellow700} />,
  },
  {
    label: 'Orange',
    value: ColorTheme.Orange600,
    icon: <ColorIcon $color={ColorTheme.Orange600} />,
  },
  {
    label: 'Red',
    value: ColorTheme.Red600,
    icon: <ColorIcon $color={ColorTheme.Red600} />,
  },
  {
    label: 'Pink',
    value: ColorTheme.Pink600,
    icon: <ColorIcon $color={ColorTheme.Pink600} />,
  },
  {
    label: 'Purple',
    value: ColorTheme.Purple600,
    icon: <ColorIcon $color={ColorTheme.Purple600} />,
  },
  {
    label: 'Violet',
    value: ColorTheme.Violet500,
    icon: <ColorIcon $color={ColorTheme.Violet500} />,
  },
];

export const TextColorDropdown = ({
  onSelect, onClear, button,
}: TextColorDropdownProps) => {
  const editor = useEditorContext(ctx => ctx.editor);
  const colorValue = editor.getAttributes('textStyle').color as string | undefined;
  return (
    <ToggleDropdown
      placement="bottom"
      layer={Layer.DropdownModalZ3}
      offsetY={16}
      button={({ onClick }) => button(onClick)}
      content={() => (
        <SelectPanel
          options={editorColorOptions}
          onOptionChange={(option) => {
            onSelect(option.value);
          }}
          hideSearch
          clearLabel="Clear"
          onClearValue={colorValue ? onClear : undefined}
        />
      )}
    />
  );
};
