import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import React, { useCallback, useMemo, forwardRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';
import { useDebouncedCallback } from 'use-debounce';

import { DocAttributes } from 'src/components/DocAttributes';
import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { EditProperty, OnValueSelectedParams } from 'src/components/EditProperty';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useDocProductAreaUpdate } from 'src/hooks/doc/useDocProductAreaUpdate';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { isAttributeScalar } from 'src/utils/attributes.util';

interface Props {
  className?: string;
  doc: DocBaseFragment;
  displayPrimaryAttributes?: boolean;
  dropdownPlacement?: Placement;
  layer?: Layer;
  limitSize?: boolean;
  readOnly?: boolean;
  readOnlyAttributeDefinitionIds?: string[];
  readOnlyStatus?: boolean;
  isDocTypeReadOnly?: boolean;
  showDoctype?: boolean;
  showDocId?: boolean;
  /**
   * show assignee in attributes
   */
  showAssignee?: boolean;
  showCustomer?: boolean;
  showStatus?: boolean;
  showLinear?: boolean;
  showAiState?: boolean;
  showRelease?: boolean;
  showSource?: boolean;
  hideStatusLabel?: boolean;
  hideIncompatibleValues?: boolean;
  hiddenAttributeDefinitionIds?: string[];
  placement?: DropdownLayerProps['placement'];
  /**
   * if defined, show assignee in edit property dropdown choice
   */
  onAssigneePropertyUpdated?: (userId: string | null, notCompatible: boolean) => void;
  disableCustomerCreation?: boolean;
  compatibleStatusIds?: string[];
  showCustomAttributes?: boolean;
  context?: 'doc-panel' | 'doc-item';
  showLinearAutoCreate?: boolean;
  onWithLinearChange?: (checked: boolean) => void;
  withLinearChecked?: boolean;
  editor?: Editor;
  showDocIdWithDocType?: boolean;
  showAddButton?: boolean;
}
const DocPanelDocAttributes = forwardRef<HTMLDivElement, Props>(({
  className,
  doc,
  displayPrimaryAttributes,
  dropdownPlacement,
  layer = Layer.Dropdown,
  limitSize,
  readOnly,
  readOnlyStatus,
  readOnlyAttributeDefinitionIds,
  isDocTypeReadOnly = false,
  showDoctype,
  showDocId = true,
  showAssignee,
  showCustomer,
  showStatus = true,
  showLinear = true,
  showAiState = true,
  showRelease = true,
  showSource,
  hideStatusLabel,
  hideIncompatibleValues,
  hiddenAttributeDefinitionIds,
  placement,
  onAssigneePropertyUpdated,
  disableCustomerCreation,
  compatibleStatusIds,
  showCustomAttributes,
  context = 'doc-panel',
  showLinearAutoCreate,
  onWithLinearChange,
  withLinearChecked,
  editor,
  showDocIdWithDocType,
  showAddButton,
}, ref) => {
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);

  const [{
    isEditPropertyShown, dropdownRect,
  }, setEditProperty] = useState<{
    isEditPropertyShown: boolean; dropdownRect: DOMRect | null;
  }>({
    isEditPropertyShown: false,
    dropdownRect: null,
  });

  const onPropertyUpdated = useCallback(async ({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) => {
    if (!propertyValue) return;
    if (isAttributeScalar(attributeDefinition)) {
      await changeDocAttributeValueDebounced({
        doc,
        attributeDefinition,
        value: propertyValue,
      });
    } else if (isValueRemoved) {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: propertyValue,
        notCompatible,
      });
    } else {
      await changeDocAttributeValue({
        doc,
        attributeDefinition,
        value: propertyValue,
        notCompatible,
      });
    }
  }, [changeDocAttributeValue, changeDocAttributeValueDebounced, doc, removeDocAttributeValue]);

  const undefinedAttributeDefinitions = useMemo(() => {
    const docDefinedAttributeIds = nodeToArray(doc.attributes).map(a => a.definition.id);
    const doctype = getDocType(doc.doctype.id);
    const docPotentialAttributes = nodeToArray(doctype?.attributeDefinitions)
      .filter(a => a && !docDefinedAttributeIds.includes(a.id) && !hiddenAttributeDefinitionIds?.includes(a.id));
    return docPotentialAttributes;
  }, [doc.attributes, doc.doctype.id, hiddenAttributeDefinitionIds]);

  const productAreaUpdate = useDocProductAreaUpdate();

  return (
    <DocAttributes
      ref={ref}
      className={twJoin('-m-0.5 overflow-hidden p-0.5', className)}
      context={context}
      doc={doc}
      displayPrimaryAttributes={displayPrimaryAttributes}
      dropdownPlacement={dropdownPlacement}
      layer={layer}
      limitSize={limitSize}
      readOnly={readOnly}
      readOnlyAttributeDefinitionIds={readOnlyAttributeDefinitionIds}
      readOnlyStatus={readOnlyStatus}
      showStatus={showStatus}
      showDocType={showDoctype}
      showAssignee={showAssignee}
      showCustomer={showCustomer}
      showLinear={showLinear}
      showAiState={showAiState}
      showRelease={showRelease}
      showSource={showSource}
      isDocTypeReadOnly={isDocTypeReadOnly}
      enableStatusShortcut
      hideStatusLabel={hideStatusLabel}
      hideIncompatibleValues={hideIncompatibleValues}
      disableCustomerCreation={disableCustomerCreation}
      compatibleStatusIds={compatibleStatusIds}
      showCustomAttributes={showCustomAttributes}
      showDocId={showDocId}
      showLinearAutoCreate={showLinearAutoCreate}
      onWithLinearChange={onWithLinearChange}
      withLinearChecked={withLinearChecked}
      editor={editor}
      showDocIdWithDocType={showDocIdWithDocType}
      showAddButton={showAddButton}
    >
      {!readOnly && (undefinedAttributeDefinitions.length > 0 || (showAssignee && onAssigneePropertyUpdated)) && (
        <DropdownLayer
          layer={layer}
          visible={isEditPropertyShown}
          hide={() => setEditProperty(current => ({
            ...current,
            isEditPropertyShown: false,
          }))}
          placement={placement}
          content={(
            <EditProperty
              docId={doc.id}
              possibleAttributes={undefinedAttributeDefinitions}
              onValueUpdated={onPropertyUpdated}
              hideIncompatibleValues={hideIncompatibleValues}
              onAssigneeUpdated={onAssigneePropertyUpdated}
              compatibleStatusIds={compatibleStatusIds}
              productAreasSelected={nodeToArray(doc.productAreas).map(area => area.id)}
              onProductAreaRemove={productAreaId => productAreaUpdate.remove({
                docId: doc.id,
                productAreaId,
              })}
              onProductAreaUpdate={data => productAreaUpdate.update({
                docId: doc.id,
                productAreaId: data.productAreaId,
                isMulti: data.isMulti,
              })}
            />
          )}
          {...dropdownRect && {
            getReferenceClientRect: () => dropdownRect,
          }}
        >
          <Tooltip
            content="Add property"
            placement="top"
            withWrapper={false}
          >
            <button
              className={twJoin(
                'btn-tertiary btn-square size-5 text-disabled',
                isEditPropertyShown && 'btn-hover text-primary',
              )}
              onClick={(e) => setEditProperty(current => ({
                dropdownRect: e.currentTarget.getBoundingClientRect(),
                isEditPropertyShown: !current.isEditPropertyShown,
              }))}
            >
              <AddIcon size={12} />
            </button>
          </Tooltip>
        </DropdownLayer>
      )}
    </DocAttributes>
  );
});

export default DocPanelDocAttributes;
