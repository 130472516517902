import { UpdateInsightQuoteDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateQuoteContent = () => {
  const [mutate] = useSafeMutation(UpdateInsightQuoteDocument);

  const updateQuoteContent = ({
    targetId, sourceId, docSourceId, content,
  }: {
    // Insight doc id
    targetId: string;
    // Feedback doc id
    sourceId: string;
    // Source id
    docSourceId: string;
    // New content
    content: string;
  }) => mutate({
    variables: {
      targetId,
      content,
      sourceId,
    },
    optimisticResponse: {
      changeDocLink: {
        __typename: 'Doc',
        id: targetId,
        docSource: {
          __typename: 'DocSource',
          id: docSourceId,
          content,
        },
      },
    },
  });

  return { updateQuoteContent };
};