import { useLocationSelector } from 'src/hooks/router/useLocation';
import { getSearchParams } from 'src/utils/routing.utils';

/**
 * Side modal ids (quote and customer/company) are stored in url search params,
 * and sorted by the order they were opened
 * @returns Id of last modal opened
 */
const getLastSideModalId = () => [...getSearchParams().entries()]
  .filter(([key]) => key === 'quote' || key === 'profile')
  .at(-1)?.[1];

/**
 * Check if the modal is the last opened
 */
export const useIsLastOpenedSideModal = (id: string | null) => {
  return useLocationSelector(() => !!id && !!getLastSideModalId()?.includes(id));
};