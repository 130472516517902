import { Button, typo, ActionButton, ShyScrollbarCss, InsightCard } from '@cycle-app/ui';
import { ContextBlock, ShowMore } from '@cycle-app/ui/components/InsightCard/InsightCard.styles';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
    display: flex;
    flex-direction: column;
    width: 720px;
    padding: 24px 12px 24px 24px;
  `;

export const Title = styled.p`
    ${typo.headerLight}
    color: ${p => p.theme.colors.text.primary};
    display: inline-flex;
    align-items: center;
    gap: 6px;
  `;

export const CloseButton = styled(ActionButton)`
    position: absolute;
    top: 24px;
    right: 24px;
  `;

export const ListContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `;

export const List = styled.div`
    height: 100%;
    ${ShyScrollbarCss}
    overflow: auto;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

export const Footer = styled.div`
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }
  `;

export const ListItem = styled.div<{ $selected: boolean }>`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid;
    border-color: ${p => (p.$selected ? 'hsla(var(--cycle), 0.08)' : p.theme.colors.border.hover)};
    background: ${p => (p.$selected ? 'hsla(var(--cycle), 0.08)' : p.theme.colors.background.secondary)};
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    ${p => p.theme.isDark && `
      border-color: transparent;
      background: ${p.$selected ? 'hsla(var(--cycle500), 0.2)' : Color.Grey850};
    `};
  `;

export const SubmitButton = styled(Button)<{ $disabled: boolean }>`
  ${p => p.$disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledInsightCard = styled(InsightCard)`
  overflow: hidden;
  cursor: pointer !important;
  .ProseMirror * {
    cursor: pointer;
  }
  margin: 4px 4px 6px 4px;

  background: ${p => (p.theme.isDark ? Color.Grey800 : Color.White)};

  ${ContextBlock} {
    background: transparent;
    padding: 0 16px;
    .ProseMirror {
      padding: 0 !important;
    }
    :before {
      background: ${p => (p.theme.isDark ? 'rgba(255, 255, 255, .15)' : p.theme.colors.border.primary)};
    }
  }

  ${ShowMore} {
    --context-bg: ${p => p.theme.colors.extractInsights.quote.bg};
    bottom: 0px;
  }
`;

export const SelectButton = styled(Button).attrs({
  size: 'M',
  variant: 'light',
})`
  --bg: transparent;
  font-weight: 500;
  height: 32px;
  padding: 0 12px;
`;
