import { useFragment } from '@apollo/client';
import { DocSourceContentFragmentDoc } from '@cycle-app/graphql-codegen';
import { twJoin } from 'tailwind-merge';

import { ReadOnlyEditor } from 'src/components/Editor';

type Props = React.ComponentProps<'div'> & {
  docSourceId: string;
};

export const QuoteContentPreview = ({
  docSourceId, className, ...props 
}: Props) => {
  const {
    data, complete, 
  } = useFragment({
    fragment: DocSourceContentFragmentDoc,
    from: docSourceId,
  });
  
  if (!complete) return null;
  
  const content = data.content ?? '';
  const firstParagraph = /<p.*?<\/p>/g.exec(content)?.[0];
  const isHtml = !!content?.startsWith('<');  

  return (
    <div
      className={twJoin(
        'min-h-9 select-text rounded-md bg-grey-100 px-2.5 py-1.5 text-body text-primary dark:bg-grey-800/50',
        isHtml ? 'quote-content-preview' : 'overflow-anywhere line-clamp-3',
        className,
      )}
      {...props}
    >
      {isHtml ? <ReadOnlyEditor content={firstParagraph} /> : content}
    </div>
  );
};
