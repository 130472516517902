import { SectionType } from '@cycle-app/graphql-codegen';
import memoize from 'fast-memoize';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import { matchPath } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';
import { history } from 'src/providers';
import { RouteParams, PathParams } from 'src/types/routes.types';
import { getBoardId, getDocId } from 'src/utils/slug.util';

export const isDocPage = (pageId: PageId) => [
  PageId.HomeDoc,
  PageId.InboxDoc,
  PageId.InsightDoc,
  PageId.RoadmapDoc,
  PageId.Doc,
  PageId.DocFullPage,
].includes(pageId);

export const isSettingsPath = (pathname: string) => matchPath(pathname, { path: routing[PageId.Settings] });

export const getViewPageId = (sectionType: SectionType) => {
  if (sectionType === SectionType.Feedback) return PageId.InboxView;
  if (sectionType === SectionType.Insights) return PageId.InsightView;
  if (sectionType === SectionType.Roadmaps) return PageId.RoadmapView;
  return PageId.Board;
};

// Paths sorted by length to make sure it matches the most specific one first
const paths = orderBy(
  Object.values(routing),
  path => path.replace('?', ''),
  'desc',
);

/**
 * Get path params from pathname
 * @example const { productId } = getPathParams('/app/cycle')
 */
export const getPathParams = memoize((pathname: string): PathParams => {
  for (const path of paths) {
    const match = matchPath<RouteParams>(pathname, { path });
    if (match) {
      const pathParams: PathParams = match.params;
      if (match.params.boardSlug) pathParams.boardId = getBoardId(match.params.boardSlug);
      if (match.params.docSlug) pathParams.docId = getDocId(match.params.docSlug);
      return pathParams;
    }
  }
  return {};
});

export const getParams = () => getPathParams(history.location.pathname);

/**
 * Get a pageId from a pathname
 * @example const pageId = getPathPageId('/login')
 */
export const getPageIdFromPathname = memoize((pathname: string): PageId => {
  for (const [pageId, path] of Object.entries(routing)) {
    if (matchPath(pathname, {
      path,
      exact: true,
    })) return pageId as PageId;
  }
  return PageId.Main;
});

export const getPageId = () => getPageIdFromPathname(history.location.pathname);

export const parentByPageId: Record<PageId, string> = {
  [PageId.Auth]: 'auth',
  [PageId.ResetPwd]: 'auth',
  [PageId.Login]: 'auth',
  [PageId.OAuth]: 'auth',
  [PageId.Callback]: 'auth',
  [PageId.SSO]: 'auth',
  [PageId.NewDoc]: 'auth',
  [PageId.GetStarted]: 'auth',
  [PageId.Main]: 'home',
  [PageId.HomeDoc]: 'home',
  [PageId.Welcome]: 'board',
  [PageId.Board]: 'board',
  [PageId.Doc]: 'board',
  [PageId.DocFullPage]: 'board',
  [PageId.Inbox]: 'inbox',
  [PageId.InboxView]: 'inbox',
  [PageId.InboxDoc]: 'inbox',
  [PageId.Insight]: 'insight',
  [PageId.InsightView]: 'insight',
  [PageId.InsightDoc]: 'insight',
  [PageId.Roadmap]: 'roadmap',
  [PageId.RoadmapView]: 'roadmap',
  [PageId.RoadmapDoc]: 'roadmap',
  [PageId.Releases]: 'releases',
  [PageId.Release]: 'releases',
  [PageId.ReleaseNote]: 'releases',
  [PageId.ReleaseDoc]: 'releases',
  [PageId.Settings]: 'settings',
  [PageId.SettingsAttributes]: 'settings',
  [PageId.SettingsFeedback]: 'settings',
  [PageId.SettingsInsights]: 'settings',
  [PageId.SettingsFeatures]: 'settings',
  [PageId.SettingsFeature]: 'settings',
  [PageId.SettingsUsers]: 'settings',
  [PageId.SettingsIntegrations]: 'settings',
  [PageId.SettingsAPI]: 'settings',
  [PageId.SettingsCustomers]: 'settings',
  [PageId.SettingsCustomer]: 'settings',
  [PageId.SettingsCompanies]: 'settings',
  [PageId.SettingsCompany]: 'settings',
  [PageId.SettingsBilling]: 'settings',
  [PageId.SettingsReleases]: 'settings',
  [PageId.RequestMakerAccess]: 'billing-update',
  [PageId.Changelog]: 'settings',
  [PageId.Video]: 'video',
  [PageId.Customer]: 'customer',
  [PageId.Company]: 'company',
  [PageId.Quote]: 'quote',
};

export const getParentFromPathname = (pathname: string) => parentByPageId[getPageIdFromPathname(pathname)];

export const getParentPage = () => parentByPageId[getPageId()];

/**
 * Create a URL from a pageId and path params
 * @example const url = createUrl(PageId.RoadmapView, { boardSlug: 'marathon' }, { productSlug: 'cycle' })
 */
export const createUrl = (pageId: PageId, newParams: RouteParams = {}, urlParams: RouteParams = {}): string => {
  const params = {
    ...urlParams,
    ...newParams,
  };
  const url = routing[pageId]
    .replace(':productSlug', params.productSlug ?? '')
    .replace(':boardSlug', params.boardSlug ?? '')
    .replace(':docSlug', params.docSlug ?? '')
    .replace(':doctypeId', params.doctypeId ?? '')
    .replace(':attributeId', params.attributeId ?? '')
    .replace(':customerId', params.customerId ?? '')
    .replace(':companyId', params.companyId ?? '')
    .replace(':quoteId', params.quoteId ?? '')
    .replace(':releaseId', params.releaseId ?? '')
    .replace(':noteId', params.noteId ?? '')
    .replace(':plan', params.plan ?? '')
    .replace(':src', params.src ?? '')
    .replace(':dashboardId', params.dashboardId ?? '');
  return `${url}${params?.hash ? `#${params.hash}` : ''}`;
};

/**
 * Get a URL from a pageId, current path params and new path params
 * @example const url = getUrl(PageId.RoadmapDoc, { docSlug: 'hello' })
 */
export const getUrl = (pageId: PageId, params: RouteParams = {}): string => {
  return createUrl(pageId, params, getParams());
};

export const getBoardUrl = (sectionType: SectionType, boardSlug: string) => {
  return getUrl(getViewPageId(sectionType), { boardSlug });
};

/**
 * Remove the specified props from the location state
 * @example removeLocationStateProps('verifyQuotes');
 * @example removeLocationStateProps(['verifyQuotes', 'rightPanel']);
 */
export const removeLocationStateProps = (props: string | string[]) => {
  history.replace(
    window.location.pathname,
    omit(history.location.state, props),
  );
};

export const getSearchParams = () => new URLSearchParams(history.location.search);
