/* eslint-disable no-nested-ternary */
import { DocSourceFragment, DocSourceUpdateDocument } from '@cycle-app/graphql-codegen';
import { Tag, TagProps, CycleLogo, Input, Icon } from '@cycle-app/ui';
import {
  IntercomIcon, EmailIcon, ChromeIcon, ZapierIcon,
  GoogleMeetIcon, MicrosoftTeamsIcon, ZoomIcon, CannyIcon, RedditIcon, TwitterIcon,
  LoomIcon, MakeIcon, MiroIcon, ModjoIcon, MondayIcon, OutlookIcon,
  AsanaIcon, GainsightIcon, GongIcon, GrainIcon, ProductBoardIcon, ShortcutIcon,
  ZendeskIcon, GitLabIcon, PitchIcon, PinterestIcon, SnowflakeIcon,
  SalesforceIcon, FrontIcon, FullStoryIcon, SkypeIcon, SliteIcon, DescriptIcon,
  DiscordIcon, DovetailIcon, DropboxIcon, FreshDeskIcon,
  AttioIcon, GoogleSheetsIcon, ClickUpIcon, ClaapIcon, ConfluenceIcon,
  G2Icon, TrustpilotIcon, CapterraIcon,
} from '@cycle-app/ui/icons';
import vitallyImageUrl from '@cycle-app/ui/icons/social/vitally.png';
import { isUrl } from '@cycle-app/utilities';
import {
  useCallback,
  useState,
  forwardRef,
  useReducer,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useSafeMutation } from 'src/hooks';
import { useDocSourceUpdateCache } from 'src/hooks/api/cache/cacheDoc';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { isCycleFileSource, isCycleWithoutFileNorUrlSource } from 'src/utils/doc.util';

import {
  CycleLogoContainer, StyledImportFileIcon, ModalUrlContent, Info, Url, TooltipSubtitle,
} from './DocSource.styles';
import { getLabel, getTooltipFooter } from './DocSource.utils';
import { getDocType } from '../../reactives/docTypes.reactive';
import { Layer } from '../../types/layers.types';
import { isInsight } from '../../utils/docType.util';

export type DocSourceProps = Pick<TagProps, 'color'> & {
  source?: DocSourceFragment | null;
  showName?: boolean;
  docId: string;
  doctypeId: string;
};

export const DocSource = forwardRef<HTMLDivElement, DocSourceProps>(({
  source, ...props
}, ref) => {
  if (!source) return null;

  return (
    <DocSourceContent {...{
      ref,
      source,
      ...props,
    }}
    />
  );
});

type DocSourceContentProps = Pick<TagProps, 'color'> & {
  source: DocSourceFragment;
  showName?: boolean;
  docId: string;
  doctypeId: string;
};

const DocSourceContent = forwardRef<HTMLDivElement, DocSourceContentProps>(({
  source, docId, showName = true, color = 'grey', doctypeId,
}, ref) => {
  const isDoctypeInsight = isInsight(getDocType(doctypeId));
  const [error, setError] = useState('');
  const [isVisible, toggleVisible] = useReducer((a) => !a, false);
  const [urlValue, setUrlValue] = useState(source.url);
  const updateCache = useDocSourceUpdateCache();
  const [docSourceUpdate] = useSafeMutation(DocSourceUpdateDocument, {
    onCompleted: (response) => {
      if (!response.updateDocSource?.source) return;
      updateCache(docId, response.updateDocSource.source);
    },
  });
  const setValueDebounced = useDebouncedCallback((value: string) => {
    updateDocSource(value);
  }, INPUT_ONCHANGE_DEBOUNCE);
  const updateDocSource = useCallback((url: string) => {
    if (url === source.url) return;
    if (!isUrl(url, { strict: false })) {
      setError('You should provide a valid URL');
      return;
    }
    setError('');
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    docSourceUpdate({
      variables: {
        docId,
        url,
      },
    });
  }, [docId, docSourceUpdate, source.url]);
  const onKeyPress = (url: string) => {
    if (!isVisible) return;
    if (url !== source.url) {
      updateDocSource(url);
    }
    toggleVisible();
  };
  useAppHotkeys('enter', () => onKeyPress(urlValue));
  useAppHotkeys('escape', () => onKeyPress(urlValue));
  const canEdit = !isDoctypeInsight && (source.__typename === 'SourceZapier' || source.__typename === 'SourceCycle');

  return (
    <DropdownLayer
      layer={Layer.ModalZ3}
      content={(
        <ModalUrlContent>
          <Input
            defaultValue={urlValue}
            autoFocus
            onChange={(e) => {
              const value = !isUrl(e.target.value) ? `https://${e.target.value}` : e.target.value;
              setUrlValue(value);
              setValueDebounced(value);
            }}
            error={error || undefined}
          />
        </ModalUrlContent>
      )}
      visible={isVisible}
      hide={() => {
        toggleVisible();
        if (source.url === urlValue) return;
        setUrlValue(urlValue);
        updateDocSource(urlValue);
      }}
      placement="bottom-start"
    >
      <Tag
        ref={ref}
        color={color}
        icon={<SourceIcon source={source} size={12} />}
        onClick={canEdit ? (e) => {
          e.preventDefault();
          e.stopPropagation();
          setUrlValue(source.url);
          toggleVisible();
        } : undefined}
        onClickLink={() => {
          const url = source.__typename === 'SourceMail' ? `mailto:${source.url}` : source.url;
          window.open(url, '_blank');
        }}
        onClickCopyLink={isCycleWithoutFileNorUrlSource(source) && !isDoctypeInsight ? undefined : () => copyToClipboard({
          text: source.url,
          notification: 'Link successfully copied to clipboard',
        })}
        tooltip={{
          content: (
            <div>
              <Info>
                <SourceIcon source={source} size={12} />
                <span>{getLabel(source)}</span>
              </Info>
              {!isCycleWithoutFileNorUrlSource(source) && (
                <Url>
                  {source.url}
                </Url>
              )}
              {!isDoctypeInsight && (
                <TooltipSubtitle>
                  {getTooltipFooter(source)}
                </TooltipSubtitle>
              )}
            </div>
          ),
          placement: 'top',
          withPortal: true,

        }}
        linkTooltip="Click to open"
      >
        {showName && getLabel(source)}
      </Tag>
    </DropdownLayer>
  );
});

export const getIconBasedOnUrl = (url: string, size: number, defaultIcon: JSX.Element) => {
  if (url.includes('docs.google.com/spreadsheets')) return <GoogleSheetsIcon size={size} />;
  if (url.includes('docs.google.com/document')) return (
    <Icon
      name="brand/notion"
      style={{
        width: size,
        height: size,
      }}
    />
  ); // todo
  if (url.includes('attio.com')) return <AttioIcon size={size} />;
  if (url.includes('clickup.com')) return <ClickUpIcon size={size} />;
  if (url.includes('claap.io')) return <ClaapIcon size={size} />; // e.g. https://app.claap.io/cycle/share-your-claaps-c-aZ6f2FkC_j-RZJEfe8crKKx
  if (url.includes('atlassian.net')) return <ConfluenceIcon size={size} />; // e.g. https://cycle-team.atlassian.net/l/cp/mBniDYfb
  // if (url.includes('jira.com')) return < size={size} />; // COnflictual with confluence e.g. https://cycle-team.atlassian.net/browse/KAN-3?atlOrigin=eyJpIjoiYzIxOGIzOTQ2NjdhNGQ3Yzk5MjUyYzYyNGFlOGI4ZGQiLCJwIjoiaiJ9
  if (url.includes('descript.com')) return <DescriptIcon size={size} />; // e.g. https://share.descript.com/view/lcEZpDJoXPa
  if (url.includes('discord.com')) return <DiscordIcon size={size} />;
  if (url.includes('dovetail.com')) return <DovetailIcon size={size} />; // e.g. https://cycle-yyv4.dovetail.com/data/3jPeauRlJ2IihgVGy2LxID
  if (url.includes('dropbox.com')) return <DropboxIcon size={size} />; // e.g. https://www.dropbox.com/s/luyfzjww5uokmsf/Enregistrement%202024-03-05%20%C3%A0%2013.13.40.webm?dl=0
  if (url.includes('freshdesk.com')) return <FreshDeskIcon size={size} />; // e.g. https://cycle.freshdesk.com/a/tickets/2
  if (url.includes('front.com')) return <FrontIcon size={size} />;
  if (url.includes('fullstory.com') || url.includes('fsty.io')) return <FullStoryIcon size={size} />;
  if (url.includes('gainsight.com')) return <GainsightIcon size={size} />;
  if (url.includes('github.com')) return (
    <Icon
      name="brand/github"
      style={{
        width: size,
        height: size,
      }}
    />
  );
  if (url.includes('gitlab.com')) return <GitLabIcon size={size} />;
  if (url.includes('gong.io')) return <GongIcon size={size} />;
  if (url.includes('grain.com')) return <GrainIcon size={size} />;
  if (url.includes('make.com')) return <MakeIcon size={size} />;
  if (url.includes('microsoft.com')) return <MicrosoftTeamsIcon size={size} />;
  if (url.includes('miro.com')) return <MiroIcon size={size} />; // e.g.https://miro.com/welcomeonboard/NlpVV0FrNkdJb0hFc0pYMW1DR1ZzVlNNSW5DYmdLU3B0Nk1abnFlam1tZmlVQnZ1TTdPaVh3eldPS0hKdDAyOXwzNDU4NzY0NTI2MTQ5Mzc0MjIyfDI=?share_link_id=935171778387
  if (url.includes('modjo.com') || url.includes('modjo.ai')) return <ModjoIcon size={size} />;
  if (url.includes('monday.com')) return <MondayIcon size={size} />;
  if (url.includes('outlook.com')) return <OutlookIcon size={size} />;
  if (url.includes('productboard.com')) return <ProductBoardIcon size={size} />;
  if (url.includes('pitch.com')) return <PitchIcon size={size} />;
  if (url.includes('pinterest.com')) return <PinterestIcon size={size} />;
  if (url.includes('shortcut.com')) return <ShortcutIcon size={size} />;
  if (url.includes('skype.com')) return <SkypeIcon size={size} />;
  if (url.includes('slite.com')) return <SliteIcon size={size} />;
  if (url.includes('snowflake.com')) return <SnowflakeIcon size={size} />;
  if (url.includes('zoom.com')) return <ZoomIcon size={size} />;
  if (url.includes('zapier.com')) return <ZapierIcon size={size} />;
  if (url.includes('airtable.com')) return (
    <Icon
      name="brand/airtable"
      style={{
        width: size,
        height: size,
      }}
    />
  );
  if (url.includes('notion.com') || url.includes('notion.so')) return (
    <Icon
      name="brand/notion"
      style={{
        width: size,
        height: size,
      }}
    />
  );
  if (url.includes('salesforce.com')) return <SalesforceIcon size={size} />;
  if (url.includes('hubspot.com')) return (
    <Icon
      name="brand/hubspot"
      style={{
        width: size,
        height: size,
      }}
    />
  );
  if (url.includes('zendesk.com')) return <ZendeskIcon size={size} />;
  if (url.includes('intercom.com')) return <IntercomIcon size={size} />;
  if (url.includes('slack.com')) return (
    <Icon
      name="brand/slack"
      style={{
        width: size,
        height: size,
      }}
    />
  );
  if (url.includes('asana.com')) return <AsanaIcon size={size} />;
  if (url.includes('canny.io')) return <CannyIcon size={size} />;
  if (url.includes('reddit.com')) return <RedditIcon size={size} />;
  if (url.includes('vitally.io')) return <img width={`${size}px`} src={vitallyImageUrl} alt="vitally-logo" />;
  if (url.includes('twitter.com') || url.includes('https://x.com')) return <TwitterIcon size={size} />;
  if (url.includes('g2.com')) return <G2Icon size={size} />;
  if (url.includes('trustpilot.com')) return <TrustpilotIcon size={size} />;
  if (url.includes('capterra')) return <CapterraIcon size={size} />;
  return defaultIcon;
};

export const SourceIcon = ({
  size, source, defaultIcon,
}: {
  source: Partial<DocSourceFragment> | null;
  size?: number;
  defaultIcon?: JSX.Element;
}) => {
  switch (source?.__typename) {
    case 'SourceSlack':
      return (
        <Icon
          name="brand/slack"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceIntercom':
      return <IntercomIcon size={size} />;
    case 'SourceMail':
      return <EmailIcon size={size} />;
    case 'SourceZapier':
      return <ZapierIcon size={size} />;
    case 'SourceHubspot':
      return (
        <Icon
          name="brand/hubspot"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZoom':
      return <ZoomIcon size={size} />;
    case 'SourceMicrosoftTeams':
      return <MicrosoftTeamsIcon size={size} />;
    case 'SourceGoogleMeet':
      return <GoogleMeetIcon size={size} />;
    case 'SourceLoom':
      return <LoomIcon size={size} />;
    case 'SourceNotion':
      return (
        <Icon
          name="brand/notion"
          style={{
            width: size,
            height: size,
          }}
        />
      );
    case 'SourceZendesk':
      return <ZendeskIcon size={size} />;
    case 'SourceSalesforce':
      return <SalesforceIcon size={size} />;
    case 'SourceWeb':
      return getIconBasedOnUrl(source?.url || '', size ?? 12, <ChromeIcon size={size} />);
    case 'SourceCycle':
    default:
      if (isCycleFileSource(source)) {
        const extension = source?.url?.split('.').at(-1);
        return <StyledImportFileIcon extension={extension} size="S" />;
      }
      return getIconBasedOnUrl(
        source?.url || '',
        size ?? 12,
        defaultIcon ?? <CycleLogoContainer><CycleLogo size={8} /></CycleLogoContainer>,
      );
  }
};
