import { useEffect, useRef, useState } from 'react';

/**
 * Detects whether an element is scrolled
 * Useful for displaying a border in the header
 **/
export const useIsScrolled = () => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const el = scrollableRef.current;
    if (!el) return;
    const handleScroll = () => {
      if (!isScrolled && el.scrollTop > 0) {
        setIsScrolled(true);
      } else if (isScrolled && el.scrollTop === 0) {
        setIsScrolled(false);
      }
    };
    el.addEventListener('scroll', handleScroll);
    return () => el.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  return [scrollableRef, isScrolled] as const;
};
