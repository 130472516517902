import { SelectPanel } from '@cycle-app/ui';
import { TrashIcon, MoreHorizIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { Layer } from 'src/types/layers.types';

import { useQuoteId } from './QuoteContext';

export const QuoteOptions = () => {
  const quoteId = useQuoteId();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <ToggleDropdown
        layer={Layer.DropdownModalZ3}
        placement="bottom-end"
        button={props => (
          <button
            className={twJoin(
              'btn-tertiary btn-square text-disabled hover:text-primary',
              props['data-active'] && 'btn-hover text-primary',
            )}
            ref={props.ref}
            onClick={props.onClick}
          >
            <MoreHorizIcon />
          </button>
        )}
        content={props => (
          <SelectPanel
            hideSearch
            onOptionChange={option => {
              props.hide();
              option.onSelect?.();
            }}
            options={[{
              value: 'delete',
              label: 'Delete quote',
              icon: <TrashIcon />,
              variant: 'danger',
              onSelect: () => {
                if (!getPermission().canDeleteInsight) {
                  setLimitationsModal({ action: 'INSIGHT_DELETE' });
                  return;
                }
                setModalOpen(true);
              },
            }]}
          />
        )}
      />

      {modalOpen && (
        <DocDeleteWarningModal
          docId={quoteId}
          onHide={() => setModalOpen(false)}
        />
      )}
    </>
  );
};