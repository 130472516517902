/* eslint-disable no-nested-ternary */
import { gql, useApolloClient } from '@apollo/client';
import { DocChildrensDocument, DoctypeType, OrderByDirection } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { getDocType } from 'src/reactives/docTypes.reactive';
import { extract } from 'src/types/graphql.types';
import { getDocChildFromCache, getDocChildrensFromCache, getDocFromCache } from 'src/utils/cache.utils';
import { defaultHierarchyPagination, hierarchyPaginationMostRecent } from 'src/utils/pagination.util';

interface Params {
  // The actions of adding/removing doc from a parent will depend on what you seend in the data.
  // eg:
  // - { newParentId: string } will update parents of each docs (only if they are different).
  // - { newParentId: undefined } will remove the parents of each docs that has a parent.
  // - when creating a new doc with a parent: { newParentId: string; docs: [{ docId: string, parentId: undefined }] }
  newParentId: string | undefined;
  docs: ({
    docId: string;
    parentId: string | undefined;
  })[];
}

interface UpdateQueryProps {
  addDoc: boolean;
  docId: string;
  doctypeId: string;
  parentId: string;
}

export const useUpdateChildCache = () => {
  const { cache } = useApolloClient();

  const updateQuery = ({
    parentId, docId, doctypeId, addDoc, direction,
  }: UpdateQueryProps & { direction: OrderByDirection }) => {
    const isMostRecent = direction === hierarchyPaginationMostRecent.direction;
    const docChildren = getDocChildFromCache(docId);
    if (!docChildren) return {};

    const docWithChildrens = getDocChildrensFromCache({
      docId: parentId,
      doctypeId,
      ...isMostRecent && hierarchyPaginationMostRecent,
    });
    if (!docWithChildrens) return {};

    const childrensData = cache.readQuery({
      query: DocChildrensDocument,
      variables: {
        docId: parentId,
        doctypeId,
        ...isMostRecent ? hierarchyPaginationMostRecent : defaultHierarchyPagination,
      },
    });

    const childrenNode = extract('Doc', childrensData?.node);

    const children = [...childrenNode?.children.edges ?? []];
    // @TODO refactor
    // Update function is called twice (eg: optimistic of updateParent + result), we need to verify if
    // the cache was already updated.
    // It is working for the increment, but we need to do the same for the decrement.
    // For now I will keep this and remove the optimistic update.
    const isChildInCacheDirty = children.some(c => c.node.id === docId);

    // Add the doc only if we have no pagination. This prevents having duplicated data
    // between the the doc we cache and the same that will be loaded after the "load more".
    if (addDoc && (isMostRecent || !docWithChildrens.children.pageInfo.hasNextPage)) {
      if (isChildInCacheDirty) {
        children.filter(c => c.node.id !== docId).splice(isMostRecent ? 0 : children.length, 0, {
          __typename: 'DocEdge',
          cursor: '',
          node: docChildren,
        });
      } else {
        children.splice(isMostRecent ? 0 : children.length, 0, {
          __typename: 'DocEdge',
          cursor: '',
          node: docChildren,
        });
      }
    }
    if (!addDoc) {
      const indexToRemove = children.findIndex(({ node }) => node.id === docId);
      if (indexToRemove >= 0) {
        children.splice(indexToRemove, 1);
      }
    }

    cache.writeQuery({
      query: DocChildrensDocument,
      variables: {
        docId: parentId,
        doctypeId,
        ...isMostRecent ? hierarchyPaginationMostRecent : defaultHierarchyPagination,
      },
      data: {
        node: {
          __typename: 'Doc',
          id: parentId,
          children: produce(docWithChildrens.children, draft => {
            // eslint-disable-next-line no-param-reassign
            draft.count = docWithChildrens.children.count + (addDoc ? isChildInCacheDirty ? 0 : 1 : -1);
            if (!isMostRecent) {
              // eslint-disable-next-line no-param-reassign
              draft.pageInfo.endCursor = children[children.length - 1]?.cursor ?? '';
            }
            // eslint-disable-next-line no-param-reassign
            draft.edges = children;
          }),
        },
      },
    });

    return { isChildInCacheDirty };
  };

  const updateParent = ({
    parentId, docId, doctypeId, addDoc,
  } : { doctypeId: string; parentId: string; docId: string; addDoc: boolean }) => {
    const docParent = getDocFromCache(parentId);
    const updatedOldest = updateQuery({
      addDoc,
      direction: OrderByDirection.Asc,
      docId,
      doctypeId,
      parentId,
    });
    const updatedMostRecent = updateQuery({
      addDoc,
      direction: OrderByDirection.Desc,
      docId,
      doctypeId,
      parentId,
    });
    const isChildInCacheDirty = updatedOldest.isChildInCacheDirty || updatedMostRecent.isChildInCacheDirty;

    if (docParent && getDocType(doctypeId)?.type !== DoctypeType.Insight) {
      cache.modify({
        id: cache.identify(docParent),
        fields: {
          childrenCount: (_, { storeFieldName }) => {
            return storeFieldName.includes(DoctypeType.Insight)
              ? docParent.insightsCount
              : docParent.childrenCount + (addDoc ? isChildInCacheDirty ? 0 : 1 : -1);
          },
        },
      });
    }
  };

  return ({
    newParentId,
    docs,
  }: Params) => {
    docs.forEach(({
      docId, parentId,
    }) => {
      if (parentId === newParentId) return;

      const docChild = getDocChildFromCache(docId);

      const doctypeId = docChild?.doctype.id;
      if (!doctypeId) return;

      cache.modify({
        id: docId,
        fields: {
          parent: () => {
            if (!newParentId) return null;
            return cache.writeFragment({
              fragment: gql`fragment Doc on Doc { id }`,
              data: { id: newParentId },
            });
          },
        },
      });

      if (newParentId) {
        updateParent({
          parentId: newParentId,
          docId,
          doctypeId,
          addDoc: true,
        });
      }
      if (parentId) {
        updateParent({
          parentId,
          docId,
          doctypeId,
          addDoc: false,
        });
      }
    });
  };
};
