import { DocTargetFragment } from '@cycle-app/graphql-codegen';
import { motion } from 'framer-motion';
import { useState, FC } from 'react';


import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { FeatureCardAttributes, FeatureTitle } from 'src/components/QuoteCards';
import { ChangeFeature, EditQuote, RemoveQuote } from 'src/components/QuotePopover';
import { EditQuoteDropdown } from 'src/components/QuotePopover/EditQuoteDropdown';
import { FeatureDropdown } from 'src/components/QuotePopover/FeatureDropdown';
import { ButtonsList, PopoverContent } from 'src/components/QuotePopover/QuotePopover.styles';
import { openQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { Layer } from 'src/types/layers.types';

interface Props {
  overridePosition: DOMRect;
  docTarget: DocTargetFragment | null | undefined;
  visible: boolean;
}

export const HighlightQuotePopover: FC<React.PropsWithChildren<Props>> = ({
  overridePosition,
  docTarget,
  visible,
}) => {
  const [removeQuoteId, setRemoveQuoteId] = useState<string | null>(null);
  const [changeFeatureId, setChangeFeatureId] = useState<string | null>(null);
  const [updateQuoteId, setUpdateQuoteId] = useState<string | null>(null);
  const quoteId = docTarget?.doc?.id;
  if (!quoteId) return null;
  return (
    <>
      {visible && (
        <motion.div
          initial={{
            opacity: 0,
            translateY: -12,
          }}
          animate={{
            opacity: 1,
            translateY: -6,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <div className="flex flex-col gap-1">
            <PopoverContent>
              <ButtonsList>
                <EditQuote
                  onClick={() => {
                    if (import.meta.env.VITE_QUOTE_PAGE === 'on') {
                      openQuoteModal(quoteId);
                      return;
                    }
                    setUpdateQuoteId(quoteId);
                  }}
                />

                <ChangeFeature
                  insightId={docTarget.id}
                  onClick={() => {
                    setChangeFeatureId(quoteId);
                  }}
                />

                <RemoveQuote
                  onClick={() => {
                    setRemoveQuoteId(quoteId);
                  }}
                />
              </ButtonsList>
            </PopoverContent>

            {docTarget.doc?.parent && (
              <PopoverContent className="flex flex-col items-start gap-2 p-3 pt-2">
                <FeatureTitle title={docTarget.doc.parent.title} />
                <FeatureCardAttributes doc={docTarget.doc.parent} />
              </PopoverContent>
            )}
          </div>
        </motion.div>
      )}
      
      {docTarget && quoteId && (
        <>
          <EditQuoteDropdown
            insight={docTarget}
            visible={updateQuoteId === quoteId}
            hide={() => setUpdateQuoteId(null)}
            placement="bottom-start"
            layer={Layer.DropdownModalZ2}
            overridePosition={overridePosition}
          />

          <FeatureDropdown
            insightId={docTarget.id}
            placement="bottom-start"
            visible={changeFeatureId === quoteId}
            hide={() => setChangeFeatureId(null)}
            layer={Layer.DropdownModalZ2}
            overridePosition={overridePosition}
          />

          {removeQuoteId === quoteId && (
            <DocDeleteWarningModal
              docId={removeQuoteId}
              onHide={() => setRemoveQuoteId(null)}
            />
          )}
        </>
      )}
    </>
  );
};
