import { useFragment } from '@apollo/client';
import { AiState, DocAiStateFragmentDoc } from '@cycle-app/graphql-codegen';
import { AiIcon, CheckIcon } from '@cycle-app/ui/icons';
 
import { useQuoteId } from './QuoteContext';

export const QuotePanelAiState = () => {
  const quoteId = useQuoteId();
  
  const { data: doc } = useFragment({
    fragment: DocAiStateFragmentDoc,
    from: quoteId,
  });
  
  if (doc?.aiState === AiState.UserValidated) {
    return (
      <div className="btn-tertiary max-w-full cursor-default gap-2 bg-grey-100 dark:bg-grey-850">
        <div className="flex items-center gap-1 text-secondary">
          <AiIcon size={14} />
          <CheckIcon size={11} />
        </div>
        <div className="truncate text-primary">
          AI-generated · user-verified
        </div>
      </div>
    );
  }

  if (doc?.aiState === AiState.AiCreated) {
    return (
      <div className="btn-tertiary cursor-default gap-2 bg-grey-100 dark:bg-grey-850">
        <AiIcon size={14} className="text-secondary" />
        <div className="truncate text-primary">
          AI-generated
        </div>
      </div>
    );
  }

  if (doc?.aiState === null) {
    return (
      <div className="btn-tertiary cursor-default bg-grey-100 dark:bg-grey-850">
        <div className="truncate text-primary">
          User-generated
        </div>
      </div>
    );
  }

  return null;
};