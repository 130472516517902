import { getHighlightHash } from '@cycle-app/utilities';
import { FC, memo } from 'react';

import LinkDraggable from 'src/components/LinkDraggable/LinkDraggable';
import { useDocContext } from 'src/contexts/docContext';
import { useLocation } from 'src/hooks';
import { openQuoteModal } from 'src/hooks/modals/useQuoteModal';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { getSelection } from 'src/reactives/selection.reactive';

import { LocationState } from '../../types/routes.types';

type Props = {
  groupId?: string | null;
  docUrl?: string;
  isDisabled: boolean;
};

export const DocItemLink: FC<React.PropsWithChildren<Props>> = memo(({
  isDisabled, ...props
}) => {
  const isCreating = useDocContext(ctx => ctx._creating);
  return isDisabled || !!isCreating
    ? <>{props.children}</>
    : <DocItemLinkClickable {...props} />;
});

const DocItemLinkClickable: FC<React.PropsWithChildren<Omit<Props, 'isDisabled'>>> = ({
  docUrl, groupId, children,
}) => {
  const docId = useDocContext(ctx => ctx.id);
  const blockId = useDocContext(ctx => ctx.docSource?.blockId);
  const path = useDocContext(ctx => ctx.path);
  const isInsight = useDocContext(ctx => ctx.isInsight);
  const location = useLocation();
  const { toggleSelectDoc: toggleSelect } = useDocsSelection();

  const state: LocationState = {
    groupId: groupId || undefined,
    fromStarredBoard: location.state?.fromStarredBoard,
    // Insight is always relative as open the feedback in panel.
    isDocRelative: isInsight || path.length > 2,
    // Handle only insights. See in usePrevNextDoc(), we check if next/prev doc is an insight and has feedback.
    docRelativeTo: isInsight ? docId : undefined,
  };
  return (
    <LinkDraggable
      to={{
        pathname: docUrl,
        hash: isInsight ? getHighlightHash({
          docId,
          blockId,
        }) : '',
        state,
      }}
      onClick={e => {
        const selection = getSelection();
        if (selection.selected.length || selection.lock) {
          e.preventDefault();
          toggleSelect?.(docId);
          return;
        }
        if (isInsight && import.meta.env.VITE_QUOTE_PAGE === 'on') {
          e.preventDefault();
          openQuoteModal(docId, e.metaKey);
          return;
        }
        if (e.shiftKey) e.preventDefault();
      }}
    >
      {children}
    </LinkDraggable>
  );
};
