import { CycleLogo } from '@cycle-app/ui';

import { useDocBase } from 'src/hooks/api/useDoc';

import { useQuoteId } from './QuoteContext';
import { SourceIcon } from '../DocSource';
import { QuotePanelLink } from './QuotePanelLink';
import { getLabel } from '../DocSource/DocSource.utils';

export const QuotePanelSource = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  if (!doc || !doc.source) return null;

  const sourceUrl = new URL(doc.source.url);
  const isSameOrigin = sourceUrl.origin === location.origin;

  return (
    <QuotePanelLink
      {...isSameOrigin ? {
        to: sourceUrl.pathname,
      } : {
        href: doc.source.__typename === 'SourceMail' ? `mailto:${doc.source.url}` : doc.source.url,
      }}
    >
      <SourceIcon
        source={doc.source}
        size={16}
        defaultIcon={<CycleLogo size={16} />}
      />
      {getLabel(doc.source)}
    </QuotePanelLink>
  );
};
