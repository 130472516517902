import { QuoteContentEditor } from './QuoteContentEditor';
import { QuotePanelAiState } from './QuotePanelAiState';
import { QuotePanelCustomer } from './QuotePanelCustomer';
import { QuotePanelFeature } from './QuotePanelFeature';
import { QuotePanelFeedback } from './QuotePanelFeedback';
import { QuotePanelProperties } from './QuotePanelProperties';
import { QuotePanelReporter } from './QuotePanelReporter';
import { QuotePanelSource } from './QuotePanelSource';

export const QuotePanelContent = () => {
  return (
    <>
      <div className="grid grid-cols-[minmax(110px,auto)_1fr] items-center gap-x-6 gap-y-4 text-body text-secondary">
        <div>
          Customer
        </div>
        <div className="justify-self-start">
          <QuotePanelCustomer />
        </div>

        <div>
          Reporter
        </div>
        <div className="justify-self-start">
          <QuotePanelReporter />
        </div>
      
        <div>
          Source
        </div>
        <div>
          <QuotePanelSource />
        </div>
      
        <div>
          AI tag
        </div>
        <div className="overflow-hidden">
          <QuotePanelAiState />
        </div>

        <div>
          Properties
        </div>
        <div className="-m-0.5 overflow-hidden p-0.5">
          <QuotePanelProperties />
        </div>
      
        <div>
          Linked feature
        </div>
        <div className="-m-0.5 overflow-hidden p-0.5">
          <QuotePanelFeature />
        </div>

        <div>
          Linked feedback
        </div>
        
        <div className="-m-0.5 overflow-hidden p-0.5">
          <QuotePanelFeedback />
        </div>
      </div>

      <hr className="my-6 w-full border-t border-grey-200 dark:border-grey-850" />

      <QuoteContentEditor />
    </>
  );
};
