import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddPropertyButton = styled(ActionButton)`
  height: 20px;
  width: 20px;

  svg {
    height: 12px;
    width: 12px;
  }
`;
