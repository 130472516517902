import { useLocationSelector } from 'src/hooks/router/useLocation';
import { getParams } from 'src/utils/routing.utils';

import { closeProfileModal, getProfileIdParam, openProfileModal } from './useProfileModal';

const CUSTOMER_ID_REGEX = /customer_(\w+)/;

export const useCustomerModal = () => useLocationSelector(getCustomerIdParam);

export const getCustomerIdParam = () => {
  const profile = getProfileIdParam();
  return profile?.match(CUSTOMER_ID_REGEX)?.[1] ?? null;
};

export const closeCustomerModal = closeProfileModal;

export const openCustomerModal = (customerId: string) => {
  // For the same customerId, the modal must not be opened if the full page is already open
  if (getParams().customerId === customerId) {
    closeProfileModal();
    return;
  }
  openProfileModal(`customer_${customerId}`);
};
