import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

import { useQuoteId } from './QuoteContext';

export const QuotePanelProperties = () => {
  const quoteId = useQuoteId();
  const { doc } = useDocBase(quoteId);
  if (!doc) return null;
  return (
    <DocPanelDocAttributes
      doc={doc}
      layer={Layer.DropdownModalZ3}
      showDocId={false}
      showDoctype={false}
      showStatus={false}
      showLinear={false}
      showAiState={false}
      showRelease={false}
      showAddButton
    />
  );
};
