import { useFragment } from '@apollo/client';
import { DocTitleFragmentDoc } from '@cycle-app/graphql-codegen';
import { Helmet } from 'react-helmet';
import { twJoin } from 'tailwind-merge';

import { createTitle } from 'src/components/PageTitle';
import { useParams } from 'src/hooks/router/useParams';

import { useQuoteId } from './QuoteContext';
import { QuoteOptions } from './QuoteOptions';
import { QuotePanelContent } from './QuotePanelContent';
import { QuotePanelLoader, QuotePanelSeleton } from './QuotePanelLoader';
import { useIsScrolled } from './useIsScrolled';

export const QuotePage = () => {
  const { quoteId } = useParams();
  if (!quoteId) return null;
  return (
    <div className="min-h-full bg-white dark:bg-grey-900">
      <QuotePanelLoader
        quoteId={quoteId}
        fallback={(
          <div className="mx-auto box-content w-[700px] max-w-full py-14">
            <QuotePanelSeleton />
          </div>
        )}
      >
        <QuotePageTitle />
        <QuotePageContent />
      </QuotePanelLoader>
    </div>
  );
};

const QuotePageTitle = () => {
  const {
    data: doc, complete, 
  } = useFragment({
    fragment: DocTitleFragmentDoc,
    from: useQuoteId(),
  });
  if (!complete) return null;
  return (
    <Helmet>
      <title>{createTitle([doc.title])}</title>
    </Helmet>
  );
};

const QuotePageContent = () => {
  const [scrollableRef, isScrolled] = useIsScrolled();
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div
        className={twJoin(
          'flex h-12 flex-none items-center justify-end px-4',
          isScrolled && 'border-b border-grey-200 dark:border-grey-850',
        )}
      >
        <QuoteOptions />
      </div>

      <div
        ref={scrollableRef}
        className="shy-scrollbar relative flex grow justify-center"
      >
        <div className="flex h-fit min-h-full w-full max-w-[700px] flex-col p-6">
          <QuotePanelContent />
        </div>
      </div>
    </div>
  );
};