import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { APP_NAME, PAGE_TITLE_SEPARATOR } from 'src/constants/app.constants';
import { PageId } from 'src/constants/routing.constant';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useIsBuiltinBoard } from 'src/hooks/api/useIsBuiltinBoard';
import { useProductBase } from 'src/hooks/api/useProduct';
import { usePageId, useParentPage } from 'src/hooks/usePageId';
import { getNativeEmoji } from 'src/utils/emoji.util';

const PageTitle = () => {
  const pageId = usePageId();
  const { doc } = useDoc();
  const board = useBoard();
  const product = useProductBase();
  const doctype = useDoctype();

  const parentPage = useParentPage();
  const isBuiltinBoard = useIsBuiltinBoard();

  const emoji = board && !isBuiltinBoard(board?.id) && parentPage === 'board' ? `${getNativeEmoji(board.emoji)} ` : '';

  const boardTitle = [
    ...(doc ? [doc.title] : []),
    ...(board ? [`${emoji}${board.name}`] : []),
  ];

  const settingsTitle = [
    ...(pageId === PageId.SettingsAttributes ? ['Properties'] : []),
    ...(pageId === PageId.SettingsUsers ? ['Members'] : []),
    ...(pageId === PageId.SettingsBilling ? ['Billing'] : []),
    ...(pageId === PageId.SettingsCustomers || pageId === PageId.SettingsCustomer ? ['Customers'] : []),
    ...(pageId === PageId.SettingsCompanies || pageId === PageId.SettingsCompany ? ['Companies'] : []),
    ...(pageId === PageId.SettingsFeedback ? ['Feedback docs'] : []),
    ...(pageId === PageId.SettingsInsights ? ['Feedback quotes'] : []),
    ...(pageId === PageId.SettingsFeatures ? ['Features'] : []),
    ...(pageId === PageId.SettingsReleases ? ['Releases'] : []),
    ...(doctype ? [`${getNativeEmoji(doctype.emoji)} ${doctype.name}`] : []),
    ...(pageId === PageId.SettingsIntegrations ? ['Integrations'] : []),
    ...(pageId === PageId.SettingsAPI ? ['API'] : []),
    'Settings',
  ];

  const pageTitle = [
    ...(parentPage === 'home' ? [...boardTitle, 'Home'] : []),
    ...(parentPage === 'board' ? boardTitle : []),
    ...(parentPage === 'settings' ? settingsTitle : []),
    ...(parentPage === 'inbox' ? [...boardTitle, 'Feedback'] : []),
    ...(parentPage === 'insight' ? [...boardTitle, 'Insights'] : []),
    ...(parentPage === 'roadmap' ? [...boardTitle, 'Roadmaps'] : []),
    ...(parentPage === 'billing-update' ? ['Update plan'] : []),
    ...(product?.name ? [product.name] : []),
  ];

  return (
    <Helmet>
      <title>{createTitle(pageTitle)}</title>
    </Helmet>
  );
};

export default PageTitle;

export const ReleasesPageTitle = ({
  releaseDate,
  releaseNoteTitle,
}: {
  releaseDate?: string;
  releaseNoteTitle?: string;
}) => {
  const title = useMemo(() => {
    const titleParts = ['Releases'];
    if (releaseDate) titleParts.unshift(releaseDate);
    if (releaseNoteTitle) titleParts.unshift(releaseNoteTitle);
    return createTitle(titleParts);
  }, [releaseDate, releaseNoteTitle]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export const ChangelogTitle = () => {
  return (
    <Helmet>
      <title>{createTitle(['Changelog'])}</title>
    </Helmet>
  );
};

export const CustomerPageTitle = ({ customerName }: { customerName: string | null | undefined }) => {
  return (
    <Helmet>
      <title>{createTitle([customerName])}</title>
    </Helmet>
  );
};

export const CompanyPageTitle = ({ companyName }: { companyName?: string | null | undefined }) => {
  return (
    <Helmet>
      <title>{createTitle([companyName])}</title>
    </Helmet>
  );
};

export const createTitle = (items: Array<string | null | undefined>) => {
  return [...items, APP_NAME].filter(Boolean).join(PAGE_TITLE_SEPARATOR).trim();
};
