import { SideModal } from 'src/components/PortalModal/SideModal';
import { navigateToCompanyPage, navigateToCustomerPage } from 'src/hooks';
import { useCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { useCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { closeProfileModal } from 'src/hooks/modals/useProfileModal';

import { CompanyProfile } from './CompanyProfile';
import { CustomerProfile } from './CustomerProfile';

export const ProfileModal = () => {
  const customerId = useCustomerModal();
  const companyId = useCompanyModal();
  return (
    <SideModal
      id={customerId || companyId}
      hide={closeProfileModal}
    >
      {!!customerId && (
        <CustomerProfile
          customerId={customerId}
          onClose={closeProfileModal}
          onExpand={() => navigateToCustomerPage(customerId)}
        />
      )}

      {!!companyId && (
        <CompanyProfile
          companyId={companyId}
          onClose={closeProfileModal}
          onExpand={() => navigateToCompanyPage(companyId)}
        />
      )}
    </SideModal>
  );
};
